<template>
  <div class="dropdown" v-bind:class="{ 'is-active': isActive }">
    <div class="dropdown-trigger" @click="toggle">
      <button
        class="button bvigencia"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
      >
        <span>{{ title }}</span>
        <span class="icon is-small">
          <i class="fas fa-calendar-alt"></i>
        </span>
        <!-- <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span> -->
      </button>
    </div>
    <div class="dropdown-menu tiempo" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <!-- <a
          href="#"
          class="dropdown-item"
          v-bind:class="{ 'is-active': selected_item == item }"
          v-for="(item, index) in $props.items"
          :key="index"
          @click="select_item(item)"
        >
          {{ item.name }}
        </a> -->
        <a
          href="#"
          class="dropdown-item"
          v-bind:class="{ 'is-active': selected_item == mnt }"
          v-for="(mnt, index) in months"
          :key="index"
          @click="select_month(mnt)"
        >
          {{ mnt.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { error_toast, post_request } from "@/utils";
import { sortBy } from "lodash";

export default {
  name: "TimeDropdown",
  props: {
    title__: {
      type: String,
      default: "Selecciona el mes/año",
    },
    show_all: {
      type: Boolean,
      default: false,
    },
    // items: {
    //   type: Array,
    //   default: () => {
    //     return [
    //       { name: "Enero 2021", value: "012021" },
    //       { name: "Febrero 2021", value: "022021" },
    //       { name: "Marzo 2021", value: "032021" },
    //       { name: "Abril 2021", value: "042021" },
    //       { name: "Mayo 2021", value: "052021" },
    //       { name: "Junio 2021", value: "062021" },
    //       { name: "Julio 2021", value: "072021" },
    //       { name: "Agosto 2021", value: "082021" },
    //       { name: "Septiembre 2021", value: "092021" },
    //       { name: "Octubre 2021", value: "102021" },
    //       { name: "Noviembre 2021", value: "112021" },
    //       { name: "Diciembre 2021", value: "122021" },
    //       { name: "Enero 2022", value: "012022" },
    //       { name: "Febrero 2022", value: "022022" },
    //       { name: "Marzo 2022", value: "032022" },
    //       { name: "Abril 2022", value: "042022" },
    //       { name: "Mayo 2022", value: "052022" },
    //       { name: "Junio 2022", value: "062022" },
    //       { name: "Julio 2022", value: "072022" },
    //       { name: "Agosto 2022", value: "082022" },
    //       { name: "Septiembre 2022", value: "092022" },
    //       { name: "Octubre 2022", value: "102022" },
    //       { name: "Noviembre 2022", value: "112022" },
    //       { name: "Diciembre 2022", value: "122022" },
    //     ];
    //   },
    // },
  },
  data() {
    return {
      isActive: false,
      title_: "",
      selected_item: undefined,
      items: [],
      months: [],
    };
  },
  computed: {
    title() {
      return this.title_ || this.title__;
    },
  },
  mounted() {
    let vm = this;
    post_request(vm, "/admin/do-get-months", { month: vm.month })
      .then((months) => {
        let shownMonths = vm.show_all
          ? months
          : months.filter((m) => m.is_shown);
        let sorted = sortBy(shownMonths, (m) => m.codename);
        //sorted.push({
        //  codename: "old",
        //  name: "Anteriores",
        //});
        // let visibleMonths = months.filter((m) => m.is_shown);
        // console.log(visibleMonths);
        this.months = sorted;
      })
      .catch((err) => {
        console.log(err);
        error_toast(vm, err);
      });
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
    select_item(item) {
      this.selected_item = item;
      this.toggle();
      this.title_ = item.name;
      this.$emit("selected", item);
    },
    setSelected(value) {
      let trgtMonth = this.months.find((i) => i.codename == value);
      if (trgtMonth) {
        let item = {
          name: trgtMonth.name,
          value: trgtMonth.codename,
        };
        this.selected_item = item;
        this.title_ = item.name;
        this.$emit("selected", item);
      }
    },
    select_month(month) {
      if (month.codename != "old") {
        let item = {
          name: month.name,
          value: month.codename,
        };
        // console.log(item);
        this.selected_item = item;
        this.toggle();
        this.title_ = item.name;
        this.$emit("selected", item);
      } else {
        window.location = "http://sharagamo.com/mccann/sanofi/guia";
      }
    },
  },
};
</script>
